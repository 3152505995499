import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Stories from './pages/Stories';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';

import './App.css';

const App: React.FC = () => {
  return (
    <div className='app'>
      <Router>
        {/* Render the NavBar at the top of all pages */}
        <Header />

        {/* Define Routes for each page */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>

      {/* Render the Footer at the bottom of all pages */}
      <Footer />
    </div>
  );
};

export default App;
