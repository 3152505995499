import React from 'react';

import picture from '../assets/stories/jde_ss.png';

export const title = "The impact of mega constellations";  // Title of the story
export const date = "2024-10-02";  // Date of the story
export const thumbnail = picture;  // Thumbnail image for the story


const Story: React.FC = () => {
  return (
    <div>
      <h2>{title}</h2>
      
        <h4>Summary of the Video</h4>

        <p>
          I recently came across a <a href="https://www.youtube.com/watch?v=T84SVowWah0">video on YouTube</a> that presented some astonishing data about the growing trend of mega constellations, specifically satellite networks like Starlink (SpaceX), Kuiper (Amazon), OneWeb, and their Chinese and European counterparts. The video paints a striking picture of the potential ecological consequences of deploying tens of thousands of satellites into orbit.
        </p>

        <h4>Key Points from the Video:</h4>

        <ul>
          <li><strong>Global Connectivity</strong>: The promise is to provide high-speed internet access to everyone, everywhere, including remote areas like the Sahara, the Amazon, and isolated islands.</li>
          <li><strong>Massive Deployment</strong>: Starlink aims to deploy 42,000 satellites, with over 6,000 already operational. Combined with other networks, this number totals <strong>77,000 satellites</strong>.</li>
          <li><strong>Short Lifespan</strong>: These satellites have a lifespan of about 5-6 years, leading to a need for constant replacement. This involves launching 12,000 new satellites per year.</li>
          <li><strong>Environmental Impact</strong>: Each launch releases significant amounts of CO2 and other pollutants into the atmosphere. The full lifecycle of a single rocket launch, including manufacturing and fuel consumption, can produce around 20,000 tonnes of CO2.</li>
          <li><strong>Forcing Radiative Effects</strong>: The pollutants released by rockets, particularly at high altitudes, contribute significantly to climate change. The video stresses that the radiative forcing effects of rocket emissions are far more severe than those of CO2 alone.</li>

        </ul>

        <h3>Ecological Consequences</h3>

        <p>
          The environmental impact of mega constellations is alarming. The production, launch, and eventual re-entry of these satellites contribute to a significant amount of pollution and radiative forcing. The video highlights that the soot and alumina particles released at high altitudes can have a radiative forcing effect <strong>thousands of times stronger than CO2</strong>. This is a critical point that seems to be underrepresented in mainstream discussions about space technology.
        </p>

        <h4>Uselessness of Connecting Desert Areas at High Costs</h4>

        <p>
          One of the key selling points of these mega constellations is the ability to provide internet connectivity to remote and underdeveloped areas. However, the video questions the practicality and necessity of this goal. Given the exorbitant costs of launching and maintaining these satellites, is it really worth it to provide high-speed internet to sparsely populated deserts and isolated regions? The existing terrestrial networks already cover the majority of populated areas, and the remaining zones may not justify the ecological cost of these mega constellations.
        </p>

        <h4>Additional Thoughts</h4>

        <ul>
          <li><strong>Financial Incentives vs. Public Good</strong>: The video suggests that the primary motivation behind these mega constellations is financial gain rather than public welfare. The high costs of these services are often prohibitive for the very people who are supposedly meant to benefit from them.</li>
          <li><strong>Scientific Missions at Risk</strong>: The proliferation of satellites could interfere with scientific missions, such as space telescopes and Earth observation satellites, which provide invaluable data for understanding our planet and the universe.</li>
          <li><strong>Public Awareness</strong>: More public awareness is needed regarding the environmental impacts of space activities. The focus often remains on the technological marvels and potential benefits, while the detrimental consequences are downplayed or ignored.</li>
        </ul>

        <p>
          In conclusion, while the idea of global high-speed internet access is appealing, it is crucial to weigh the environmental and societal costs. The rapid expansion of mega constellations calls for a careful reassessment of our priorities and a collective effort to establish responsible regulations for space activities.
        </p>
    </div>
  );
};

export default Story;