import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import './Header.css'; // Import the CSS file
import logo from '../assets/logo/logo3-white.png'; // Import the image from src folder



function Header() {

  // Function to navigate to a different page
  const navigate = useNavigate();

  // Menu items for navigation
  const items = [
    {
      label: 'Stories',
      icon: 'pi pi-book',
      command: () => {
        navigate('/stories');
      },
    },
    {
      label: 'Contact',
      icon: 'pi pi-envelope',
      command: () => {
        navigate('/contact');
      },
    },
  ];

  // Left side: Logo
  const start = (
    <Link to="/">
      <img
        src={logo}
        alt="OrbitalPass Logo"
      />
    </Link>
  );

  return (
    <Menubar model={items} start={start}/>
  );
}

export default Header;
