import './Footer.css';

function Footer() {

    return (
        <div className="myfooter">
            <div>© 2024 T.VDL. All rights reserved.</div>

            {/* Say background image is courtesy of NASA: View from ISS https://images.nasa.gov/ */}
            {/* <div>Background image courtesy of NASA: <a href="https://images.nasa.gov/">https://images.nasa.gov/</a></div> */}
            <div>v1.0.6</div>
        </div>
    );
}

export default Footer;
