import React from 'react';
import { Carousel } from 'primereact/carousel';
import './PartnerCarousel.css';

import sesLogo from '../assets/SES_White.png';

interface Company {
    name: string;
    logo: string;
    link: string;
}

const companies: Company[] = [
    { name: 'SES', logo: sesLogo, link: 'https://www.ses.com' },
    { name: 'Terraprisma', logo: 'https://terraprisma.com/wp-content/uploads/2023/08/TP_Logo_WHT-768x195.png', link: 'https://terraprisma.com/' },
];

const PartnersCarousel: React.FC = () => {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const companyTemplate = (company: Company) => {
        return (
            <div className="company-item">
                <a href={company.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="company-logo" src={company.logo} alt={company.name} />
                </a>
            </div>
        );
    };

    return (
        <div className="trusted-partners-carousel">
            <Carousel
                value={companies}
                numVisible={3}
                numScroll={1}
                circular
                autoplayInterval={3000}
                responsiveOptions={responsiveOptions}
                itemTemplate={companyTemplate}
                showIndicators={false}
                showNavigators={true}
            />
        </div>
    );
};

export default PartnersCarousel;
