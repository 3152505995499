import React from 'react';
import { Button } from 'primereact/button';

import './Contact.css';

import profilePic from '../assets/profilepic.jpeg';


const Contact: React.FC = () => {

    const handleMailTo = () => {
      window.location.href = 'mailto:consulting@orbital-pass.com';
    };

    return (
      <div className="contact-page">
        {/* Contact Info */}

        <div className="contact-info" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5rem'}}>
            <h1>Contact</h1>
            <img src={profilePic} alt="Profile" className="profile-picture"/>
          </div>
          
          <div className="profile-description">
            <p>We are here to help your business succeed with innovative and reliable solutions. Our experienced professionals are dedicated to delivering high-quality results and supporting your projects every step of the way.</p>
            <p>You can reach us through social media for updates and insights, or email us directly at consulting at orbital-pass dot com. We aim to respond quickly and provide you with a detailed quote as soon as possible.</p>
          </div>

          <div className="social-buttons">
            <a href="https://www.linkedin.com/in/tim-van-der-lee-33247b116/">
              <Button icon="pi pi-linkedin" className="p-button-rounded p-button-outlined" aria-label="LinkedIn" />
            </a>
            <a href="https://github.com/Tim024">
              <Button icon="pi pi-github" className="p-button-rounded p-button-outlined" aria-label="Github" />
            </a>
            <Button
              icon="pi pi-envelope"
              onClick={handleMailTo}
              className="p-button-rounded p-button-outlined"
            />
          </div>
        </div>

        {/* Contact Form in a Card */}
        {/* <div className="contact-form-container">
        <Card className="contact-form-card">
          <form onSubmit={handleSubmit}>
            <div className="p-field">
              <label htmlFor="name">Name</label>
              <InputText id="name" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} required />
            </div>
            <div className="p-field">
              <label htmlFor="email">Email</label>
              <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={100} required />
            </div>
            <div className="p-field">
              <label htmlFor="message">Message</label>
              <InputTextarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} rows={1} autoResize required />
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
              
              <ReCAPTCHA
                sitekey="6Lfyi1UqAAAAAHTJHA-ffIlq60Jk05NAbgk6RALi"  // Replace this with your actual site key from Google reCAPTCHA
                onChange={handleCaptchaResponse}
                ref={recaptcha}
              // size="invisible"
              // theme="dark"
              // style={{ display: 'inline-block'}}
              />
              <Button label="Submit" type="submit" className="p-button-rounded p-button-info" disabled={!captchaValid} style={{ height: "50px", alignSelf: "flex-end" }} />
            </div>
          </form>
        </Card>
      </div> */}
      </div>
    );
  };

  export default Contact;
