import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'primereact/button';

import './Stories.css';

// Define the expected interface of story modules
interface StoryModule {
  title: string;
  date: string; // assuming date is a string in your modules
  thumbnail: string;
  default: React.FC;
}

// Define the StoryMeta type for meta information
interface StoryMeta {
  slug: string;
  title: string;
  date: Date;
  thumbnail: string;
}

// Use Webpack's require.context to dynamically import all story files from the src/stories directory
const storyContext = (require as NodeRequire).context('../stories', false, /\.tsx$/);

// Load all stories
const loadStories = (): { meta: StoryMeta; Component: React.FC }[] => {
  return storyContext.keys().map((fileName: string) => {
    const module = storyContext<StoryModule>(fileName) as StoryModule; // Assert that the module matches the StoryModule interface
    const slug = fileName.replace('./', '').replace('.tsx', '');
    const { title, date, thumbnail, default: Component } = module;
    return {
      meta: {
        slug,
        title,
        date: new Date(date), // Convert the date string to a Date object
        thumbnail
      },
      Component
    };
  });
};

const Stories: React.FC = () => {
  const [currentStory, setCurrentStory] = useState<{ meta: StoryMeta; Component: React.FC } | null>(null);
  const { slug } = useParams<{ slug: string }>();
  const stories = loadStories();

  useEffect(() => {
    if (slug) {
      const story = stories.find((story) => story.meta.slug === slug);
      setCurrentStory(story || null);
    }
  }, [slug, stories]);

  if (currentStory) {
    const { Component } = currentStory;
    return (
      <div className="story-content-card">
        <div className="back-button">
          <Link to="/">
            <Button
              icon="pi pi-arrow-left"
              label="Back"
              className="p-button-text"
              onClick={() => setCurrentStory(null)}
            />
          </Link>
        </div>
        <Component />
      </div>
    );
  }

  return (
    <div className="stories-list-container">
      <h1>Stories</h1>
      <div className="stories-list">
        {stories.map((story) => (
          <div key={story.meta.slug} className="story-card">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={() => setCurrentStory(story)} className="story-link">
              <h4>{story.meta.date.toLocaleDateString('en-CA')}</h4>
              <img src={story.meta.thumbnail} alt={story.meta.title} />
              <h3>{story.meta.title}</h3>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stories;