import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

import PartnersCarousel from '../components/PartnerCarousel';


import './Home.css';


const generateRandomStars = (numStars: number) => {
  const stars = [];
  for (let i = 0; i < numStars; i++) {
    const size = Math.random() * 3 + 1; // Random size between 1px and 3px
    const top = Math.random() * 100 + 0;
    const left = Math.random() * 100;
    // Random animation duration between 10 and 70 seconds
    const animationDuration = Math.random() * 60 + 10;
    // Random color between white and lightblue
    const color = "ffffff";
    stars.push({ top: `${top}%`, left: `${left}%`, size: `${size}px`, animationDuration: animationDuration, color: color });
  }
  return stars;
};

const stars = generateRandomStars(20); // Generate 20 random stars


const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <div className='home'>

      {/* Blinking stars */}
      {/* {stars.map((star, index) => (
        <div
          key={index}
          className="star"
          style={{
            top: star.top,
            left: star.left,
            width: star.size,
            height: star.size,
            animationDuration: `${star.animationDuration}s`,
            backgroundColor: `#${star.color}`
          }}
        ></div>
      ))} */}

      {/* Empty div */}
      <div style={{ height: '100px' }}></div>

      {/* Mission Statement Card */}
      <div className="mission-card-container">
        <div className="mission-card">
          <div className="mission-card-content">
            <h1>Our Mission</h1>
            <p>
              With several years of experience in the space and software industry, we offer expert services in satellite communications, earth observation data processing, and software and cloud solutions. Our team is dedicated to guiding your space projects from the initial planning stages to successful delivery. We carefully manage every detail to make sure your projects are done right and on time.            </p>
            <p>
            We work with both new startups and established companies, customizing our solutions to fit your unique needs. By partnering with us, you benefit from our deep industry knowledge and strong commitment to doing a great job. We are here to help you succeed in the fast-changing space industry.
            </p>

            {/* <Button label="Contact Us" icon="pi pi-envelope" onClick={handleContactClick} className="p-button-rounded p-button-info" /> */}
          </div>
        </div>
      </div>


      <div style={{ height: '100px' }}></div>

      {/* Partners Carousel */}
      <PartnersCarousel />
    </div>
  );
};

export default Home;
